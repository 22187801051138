import React from "react";
import {
  MobileNavbarContainer,
  Icon,
  CloseIcon,
  MobileMenuWrapper,
  MobileMenu,
  MobileNavLink,
  MobileBtnWrap,
  MobileBtnRoute,
} from "./MobileNavbarElements";

const MobileNavbar = ({ isOpen, toggle }) => {
  return (
    <MobileNavbarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon></CloseIcon>
      </Icon>
      <MobileMenuWrapper>
        <MobileMenu>
          <MobileNavLink to="about" onClick={toggle}>
            {" "}
            About{" "}
          </MobileNavLink>
          <MobileNavLink to="discover" onClick={toggle}>
            {" "}
            Discover{" "}
          </MobileNavLink>
          <MobileNavLink to="services" onClick={toggle}>
            {" "}
            Services{" "}
          </MobileNavLink>
          {/* <MobileNavLink to="signup" onClick={toggle}>
            {" "}
            Sign Up{" "}
          </MobileNavLink> */}
        </MobileMenu>
        <MobileBtnWrap>
          <MobileBtnRoute to="/signup"> Sign up</MobileBtnRoute>
        </MobileBtnWrap>
      </MobileMenuWrapper>
    </MobileNavbarContainer>
  );
};

export default MobileNavbar;
