export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: "About us",
  headLine: "Our Goal",
  description:
    "As parents, we can relate when it comes to preparing our kids for the future. " +
    "We strongly believe it is our resposability to introduce kids early on to read and write code.  " +
    "Times are changing, software has now become a part of us and we will continue to evolve with the help of technology. " +
    // "Our goal is to give kids the real world experience software engineers face on the daily basis.  "+
    "Unicode members will learn valuable" +
    " skills that will help them grow in every area of their life.  As our kids become adults, they become independent. We believe " +
    " software will be a great skill to have under their belt regardless of persuing technology as a career." +
    "",
  buttonLable: "Sign Up for Classes",
  imgStart: true,
  img: require("../../images/teaching_1.svg").default,
  alt: "code",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Discover",
  headLine: "Get Access to our Group Sessions",
  description:
    "Imagine, Inspire, and Innovate.  " +
    "A mentoring program where kids can be themselves. We welcome ideas, encourage projects, " +
    "host competitions, and give the opportunity of advancement!  " +
    "At an early age, kids learn to code, to build life-long relationships, and develop important life skills such as: leadership, team work, " +
    "problem solving, and prioritizing tasks." +
    "  The Posibilities are Endless!",
  buttonLable: "Learn More",
  imgStart: false,
  img: require("../../images/group_access.svg").default,
  alt: "code",
  dark: false,
  primary: false,
  darkText: true,
};

// export const homeObjThree = {
//   //services prior
//   id: "pricing",
//   lightBg: false,
//   lightText: true,
//   lightTextDesc: false,
//   topLine: "University Code",
//   headLine: "Unbeliabable value for this price",
//   description: "We are hosting 3 sessions a week, two hours a day" + "",
//   buttonLable: "Sign Up for Classes",
//   imgStart: true,
//   img: require("../../images/svg-1.svg").default,
//   alt: "code",
//   dark: true,
//   primary: true,
//   darkText: false,
// };
