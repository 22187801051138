import React from "react";
import Icon1 from "../../images/html_css_js.svg";
import Icon2 from "../../images/svg-1.svg";
import Icon3 from "../../images/game_world.svg";
import Icon4 from "../../images/bitcoin.svg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./servicesElements";

const Services = () => {
  return (
    <>
      <ServicesContainer id="services">
        <ServicesH1> Our Services </ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={Icon1}></ServicesIcon>
            <ServicesH2> Web Application Development </ServicesH2>
            <ServicesP>
              {" "}
              Learn to build applications from the ground up. Starting from the
              fundamentals to advanced frameworks such as: React, and
              Angular.{" "}
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={Icon2}></ServicesIcon>
            <ServicesH2> Create Real World Applications</ServicesH2>
            <ServicesP>
              {" "}
              Get an uderstanding of how real web applications work. Learn to
              create, maintatain, update, and deliver applications.{" "}
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={Icon3}></ServicesIcon>
            <ServicesH2> Interactive JavaScript Games </ServicesH2>
            <ServicesP>
              {" "}
              Make it fun! Members get the chance to code games, share ideas, and participate in competitions. More
              than code, it's a rewarding hobbie.{" "}
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={Icon4}></ServicesIcon>
            <ServicesH2> Learn Blockchain and Crypto Currency </ServicesH2>
            <ServicesP>
              {" "}
              Digital Economy needs digital currency build on software,
              Learn to research, compare, and analyze diffent crypto projects.{" "}
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Services;
