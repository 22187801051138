import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";
import {
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        {/* <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle> About Us </FooterLinkTitle>
                                <FootLink to ="/signin"> How it works </FootLink>
                                <FootLink to ="/signin"> Testimonials </FootLink>
                                <FootLink to ="/signin"> Careers </FootLink>
                                <FootLink to ="/signin"> Investors </FootLink>
                                <FootLink to ="/signin"> Terms of Service </FootLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle> About Us </FooterLinkTitle>
                                <FootLink to ="/signin"> How it works </FootLink>
                                <FootLink to ="/signin"> Testimonials </FootLink>
                                <FootLink to ="/signin"> Careers </FootLink>
                                <FootLink to ="/signin"> Investors </FootLink>
                                <FootLink to ="/signin"> Terms of Service </FootLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle> About Us </FooterLinkTitle>
                                <FootLink to ="/signin"> How it works </FootLink>
                                <FootLink to ="/signin"> Testimonials </FootLink>
                                <FootLink to ="/signin"> Careers </FootLink>
                                <FootLink to ="/signin"> Investors </FootLink>
                                <FootLink to ="/signin"> Terms of Service </FootLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle> Social Media </FooterLinkTitle>
                                <FootLink to ="/signin"> Twitter </FootLink>
                                <FootLink to ="/signin"> Facebook </FootLink>
                                <FootLink to ="/signin"> Instagram </FootLink>
                                <FootLink to ="/signin"> Pinterest </FootLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer> */}
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              Uni.Code
            </SocialLogo>
            <WebsiteRights>
              {" "}
              Uni.Code {new Date().getFullYear()}
              All rights reserved.{" "}
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink href="/" target="_blank" aira-label="Twitter">
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aira-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aira-label="YouTube">
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aira-label="Instagram">
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aira-label="Pinterest">
                <FaPinterest />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
