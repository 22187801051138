import React from 'react'

import ConfirmImg from '../../images/confirmed.svg'

import {
    Container,
    FormWrap,
    Icon,
    FormContent,
    Form,
    FormH1,
    FormLabel,
    FormInput,
    FormButton,
    ConfirmText,
    ConfirmIcon,
    ConfirmCard,
    ConfirmWrapper
    } from './SignupElements'

    class Signup extends React.Component {
      constructor(props) {
        super(props);
        this.state={          
        };
      }
      handleChange =(event) => {
        this.setState({[event.target.name]: event.target.value});
      }

      handleSubmit = (event) => {
        event.preventDefault();
        fetch('https://unicode.academy/customerservice/unicode/api/customer', {   
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(this.state)
      }).then(response => response.json()).then(data => {
        //alert(data.responseMessage);
        this.setState({responseMessage: data.responseMessage});
      });
      }

      render() {

        if(this.state.responseMessage != null) {

          return ( 
            <>       
            <Container>
            <ConfirmWrapper>
            <Icon to='/'>Uni.Code</Icon>
              <ConfirmCard> 
              <ConfirmIcon src ={ConfirmImg}>
              </ConfirmIcon>
              </ConfirmCard>            
            </ConfirmWrapper>
            <FormWrap>
          <ConfirmText>{this.state.responseMessage}</ConfirmText>
          </FormWrap>
          </Container>
          </>
          )
        } else { 
        
        return(
          <>
        <Container>
        <FormWrap>
          <Icon to='/'>Uni.Code</Icon>
          <FormContent>
            <Form onSubmit={this.handleSubmit}>
              <FormH1>Register for more Information</FormH1>
              <FormLabel htmlFor='for'>First Name</FormLabel>
              <FormInput type='text' value={this.state.value} name="fName" onChange={this.handleChange} required />
              <FormLabel htmlFor='for'>Last Name</FormLabel>
              <FormInput type='text' name="lName" value={this.state.value} onChange={this.handleChange} required />
              <FormLabel htmlFor='for'>Email</FormLabel>
              <FormInput type='email' name='email' value={this.state.value} onChange={this.handleChange} required />
              <FormLabel htmlFor='for'>Phone Number</FormLabel>
              <FormInput type='phone' name='phone' value={this.state.value} onChange={this.handleChange} required pattern="\d+" />
              <FormButton type="submit" value="Submit">Submit</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
            
        </>
        )
        }
      }

    }

export default Signup
